code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

// Mantine overrides

// Other styles

// Giving mantine table first column some padding

.datatable tr th:first-child .mantine-TableHeadCell-Content {
  padding-left: 25px;
}

.datatable tbody tr td:first-child {
  padding-left: 40px;
}

.datatable {
  right: 10px;
}

.datatable .datatable-row.active {
  background-color: saddlebrown;
}

// Normal table

.normal-table th {
  font-weight: 600 !important;
}

// hover table

.hover-table tr:hover {
  cursor: pointer;
}

th {
  font-weight: 600 !important;
}

.mantine-TextInput-error,
.mantine-InputWrapper-error {
  font-size: 13px !important;
}

.infinite-scroll-component::-webkit-scrollbar {
  display: none;
}
